<template>
    <div>
        <div class="over_bgc" style="overflow-y:scroll;overflow-x:hidden;height:100%">
            <!-- 顶部栏 -->
            <top-banner theme="white" @backHome="backHome"></top-banner>
            <!-- 标题 -->
            <title-enzh enTitle="Product Information" zhTitle="产品资料" fontSizeEn="normal" fontSizeZh="normal"></title-enzh>
        </div>
        <div class="main x_between">
            <div v-for="(item,index) in mainList" :key="index" style="flex:1">
                <div :style="{'background':'url(' + item.bgm + ')'}" class="item_box column_x_center">
                    <div class="main_img_box xy_center" 
                         :style="{'z-index':($store.state.sideMenuState && index === 0) ? 0 : 2}">
                        <img :src="item.mainImg" style="width:100%">
                    </div>
                    <!-- 标题 -->
                    <title-enzh :enTitle="item.enTitle" :zhTitle="item.zhTitle" 
                                :fontSizeEn="index === 2 ? 'small' : 'normal'"
                                :fontSizeZh="index === 2 ? 'small' : 'normal'"
                                :style="{'z-index':($store.state.sideMenuState && index === 0) ? 0 : 2}">
                    </title-enzh>
                    <!-- 底部按钮 -->
                     <div class="btn_group x_between_y_center"
                          :style="{'z-index':($store.state.sideMenuState && index === 0) ? 0 : 2}">
                        <div class="btn" v-text="item.leftBtn" @click="downloadFile(item.productid,'pdf')"></div>
                        <div class="btn" v-text="item.rightBtn" @click="downloadFile(item.productid,'zip')"></div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
import topBanner from "@/components/topBanner.vue"
import titleEnzh from "@/components/titleEnzh.vue"
export default {
    components:{
        topBanner,
        titleEnzh
    },
    data(){
        return {
            mainList:[
                {
                    bgm:require("../assets/image/productInfomation/bimom_bgm.jpg"),
                    mainImg:require("../assets/image/home/twoPage/sofa.png"),
                    enTitle:"BIMOM",
                    zhTitle:"构件库",
                    leftBtn:"帮助手册",
                    rightBtn:"下载BIMOM构件库",
                    productid:'BIMOM'
                },
                {
                    bgm:require("../assets/image/productInfomation/bimom_bgm.jpg"),
                    mainImg:require("../assets/image/home/threePage/explode.png"),
                    enTitle:"BIMOM",
                    zhTitle:"VisualMind",
                    leftBtn:"帮助手册",
                    rightBtn:"下载BIMOM-VM",
                    productid:'BIMOM-VM'
                },
                {
                    bgm:require("../assets/image/productInfomation/window_bgm.jpg"),
                    mainImg:require("../assets/image/home/fourPage/window.png"),
                    enTitle:"BIMOM",
                    zhTitle:"建筑工具",
                    leftBtn:"帮助手册",
                    rightBtn:"下载BIMOM-建筑",
                    productid:'BIMOM-ARC'
                },
                {
                    bgm:require("../assets/image/productInfomation/discrete_bgm.jpg"),
                    mainImg:require("../assets/image/home/DecorationPage/decoration.png"),
                    enTitle:"BIMOM",
                    zhTitle:"装饰工具",
                    leftBtn:"帮助手册",
                    rightBtn:"下载BIMOM-装饰",
                    productid:'BIMOM-Dec'
                },
                {
                    bgm:require("../assets/image/productInfomation/aluminum_cast_bgm.jpg"),
                    mainImg:require("../assets/image/home/fivePage/formwork.png"),
                    enTitle:"BIMOM",
                    zhTitle:"铝模设计",
                    leftBtn:"帮助手册",
                    rightBtn:"下载BIMOM-铝模",
                    productid:'BIMOM-LM'
                },

            ]
        }
    },
    created(){
        this.$store.commit("showSideMenu",false)
    },
    methods:{
        backHome(){
            this.$router.push("/")
        },
        downloadFile(productid,type) {
            let config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            let param = {
                product_id:productid,
                product_type:type
            }
            this.axios.post(this.VUE_APP_BASE_API + '/ProductDownload/GetproductInfo', param, config).then((res) => {
                if (res.data.resultCode == 0) {
                    let path=res.data.resultData;
                    console.log(path);
                    window.open(res.data.resultData);
                } else {
                    alert(res.data.resultMsg);
                }
            });
        },
        openPDF(productid){
            // alert(name+" 即将上线，敬请期待！")
            let para = {
                product_id: productid
            }
            this.axios({
                method: 'post',
                url: this.VUE_APP_BASE_API + '/ProductDownload/ProductDownloadPDF',
                responseType: 'blob',
                data: para
            }).then(res => {
                    if (res.data.type == 'application/json') {
                        this.handlerResponseError(res.data);
                    } else {
                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                        // let fileName = Date.parse(new Date()) + '.zip'
                        let fileName = decodeURIComponent(res.headers['filename']);// 'BIMOM构件库V1.0.zip'
                        console.log(fileName);
                        if (window.navigator.msSaveOrOpenBlob) {
                            // console.log(2)
                            navigator.msSaveBlob(blob, fileName)
                        } else {
                            // console.log(3)
                            var link = document.createElement('a')
                            link.href = window.URL.createObjectURL(blob)
                            link.download = fileName
                            link.click()
                            //释放内存
                            window.URL.revokeObjectURL(link.href)
                        }
                    }
                },
                err => {
                    alert(err);
                }
            );
        },
        download(productid){
            //alert(name+" 即将上线，敬请期待！")
            let para = {
                product_id: productid
            }
            this.axios({
                method: 'post',
                url: this.VUE_APP_BASE_API + '/ProductDownload/ProductDownloadZip',
                responseType: 'blob',
                data: para
            }).then(res => {
                    if (res.data.type == 'application/json') {
                        this.handlerResponseError(res.data);
                    } else {
                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                        // let fileName = Date.parse(new Date()) + '.zip'
                        let fileName = decodeURIComponent(res.headers['filename']);// 'BIMOM构件库V1.0.zip'
                        console.log(fileName);
                        if (window.navigator.msSaveOrOpenBlob) {
                            // console.log(2)
                            navigator.msSaveBlob(blob, fileName)
                        } else {
                            // console.log(3)
                            var link = document.createElement('a')
                            link.href = window.URL.createObjectURL(blob)
                            link.download = fileName
                            link.click()
                            //释放内存
                            window.URL.revokeObjectURL(link.href)
                        }
                    }
                },
                err => {
                    alert(err);
                }
            );
        },
        handlerResponseError(data) {
            const _this = this;
            const fileReader = new FileReader();
            fileReader.onload = function() {
                try {
                    const jsonData = JSON.parse(fileReader.result); // 说明是普通对象数据，后台转换失败
                    console.log('后台返回的信息',jsonData.resultMsg);
                    alert(jsonData.resultMsg);
                    // dosomething……
                } catch (err) { // 解析成对象失败，说明是正常的文件流
                    console.log('success...');
                }
            };
            fileReader.readAsText(data);
        },
    },
}
</script>

<style lang="scss" scoped>
.over_bgc{
    position: absolute;
    width: 100%;
    min-height: 100vh;
    background: rgba($color: #000000, $alpha: .7);
    padding: 50px 70px !important;
    z-index: 1;
}
.main{
    position: absolute;
    left:0;
    width: 100vw;
    height: 100vh;
    .item_box{
        background-repeat: no-repeat;
        width: 100%;
        height: 100vh;
        background-size: contain;
        padding-top: 300px;
        position: relative;
        .main_img_box{
            width: 300px;
            height: 400px;
            z-index: 2;
        }
        
    }
}
.btn_group{
    margin-top: 60px;
    width: 65%;
    position: relative;
    z-index: 2;
    .btn{
        width: 140px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #797979;
        border-radius: 20px;
        font-family: '幼圆', sans-serif;
        font-size: 14px;
        color: #797979;
        cursor: pointer;
        &:hover{
        color: #02A7F0;
        border: 1px solid #02A7F0;
        }
    }
}

</style>